import React from 'react'


import InputNoAutoComplete from '../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'

import {
  normalizerCombiner,
  nonZeroPaddedPositiveIntegerOrZero,
  nonZeroPaddedPositiveOrNegativeIntegerOrZero,
  maxLength,
  maxLengthConditionalLength,
} from '../../../utils/formNormalization'


export default ({
  name,
  placeholder='QTY',
  formValues,
  changeFormValue,
  negativeValuesAllowed,
}) => (
  <InputNoAutoComplete
    value={formValues[name] || ''} // CODE_COMMENTS_245
    onChange={(e, { value }) => {
      changeFormValue({
        fieldName: name,
        value,
        normalizer: (
          negativeValuesAllowed
            ? normalizerCombiner(
              nonZeroPaddedPositiveOrNegativeIntegerOrZero,
              maxLengthConditionalLength(val => (val[0] === '-' ? 6 : 5)),
            )
            : normalizerCombiner(nonZeroPaddedPositiveIntegerOrZero, maxLength(5))
        ),
      })
    }}
    placeholder={placeholder}
    inputMode="numeric"// CODE_COMMENTS_275: opens the numeric keyboard on a phone
    pattern="[0-9]*"
    onKeyDown={e => {
        if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault()
        }
    }}
    onBeforeInput={e => {
        if (['e', 'E', '+', '-'].includes(e.data)) {
            e.preventDefault()
        }
    }}
  />
)

/**
 * CODE_COMMENTS_31
 */

import {
  getDisplayedDateFormat,
} from '../../utils'


/*
 * *****************************************************************************
 * General
 * *****************************************************************************
 */

// CODE_COMMENTS_243
export const DEFAULT_DISPLAYED_DATE_FORMAT = getDisplayedDateFormat()

export const DEFAULT_API_DATE_FORMAT = 'YYYY-MM-DD'

/*
 * *****************************************************************************
 * Statuses of history items
 * *****************************************************************************
 */


// All statuses the API might possibly return for all objects that have a
// `status` prop (keg orders, collar orders, and shipments). This is not
// necessarily a list of choices displayed to the user: those settings are
// elsewhere.

// No object will actually have an `ALL` status, this is just a way to give a
// name to the "All" status dropdown option in the history forms. Also, see
// CODE_COMMENTS_61
export const COMMON_STATUS_ALL = 'ALL'

export const KEG_ORDER_STATUS_PENDING = 'PEN'
export const KEG_ORDER_STATUS_ACCEPTED = 'ACC'
export const KEG_ORDER_STATUS_COMPLETED = 'COM'
export const KEG_ORDER_STATUS_CANCELED = 'CXL'
export const KEG_ORDER_STATUS_ON_HOLD = 'HLD'

export const COLLAR_ORDER_STATUS_NEW_ORDER = 'NEW'
export const COLLAR_ORDER_STATUS_ACCEPTED = 'ACC'
export const COLLAR_ORDER_STATUS_PRINTING = 'PRN'
export const COLLAR_ORDER_STATUS_SHIPPING = 'SHP'
export const COLLAR_ORDER_STATUS_COMPLETED = 'COM'
export const COLLAR_ORDER_STATUS_CANCELED = 'CXL'
export const COLLAR_ORDER_STATUS_IN_REVIEW = 'RVW'
export const COLLAR_ORDER_STATUS_READY_TO_POST = 'RTP'
export const COLLAR_ORDER_STATUS_PRE_POST = 'PRE'
export const COLLAR_ORDER_STATUS_POSTED = 'POST'
export const COLLAR_ORDER_STATUS_SENT_TO_GP = 'SENTTOGP'
export const COLLAR_ORDER_STATUS_INVOICED = 'INV'

export const SHIPMENT_STATUS_PENDING = 'PEN'
export const SHIPMENT_STATUS_ALLOCATED = 'ALLOC'
export const SHIPMENT_STATUS_BOOKED = 'BOOKED'
export const SHIPMENT_STATUS_IN_TRANSIT = 'INTRAN'
export const SHIPMENT_STATUS_DELIVERED = 'DEL'
export const SHIPMENT_STATUS_ACKNOWLEDGED = 'ACK'
export const SHIPMENT_STATUS_COMPLETED = 'COM'
export const SHIPMENT_STATUS_CANCELED = 'CXL'
export const SHIPMENT_STATUS_ON_HOLD = 'HOLD'
export const SHIPMENT_STATUS_UNDER_REVIEW = 'REVIEW'
export const SHIPMENT_STATUS_PRE_POST = 'PRE'
export const SHIPMENT_STATUS_READY_TO_POST = 'RTP'
export const SHIPMENT_STATUS_POSTED = 'POST'
export const SHIPMENT_STATUS_PARTIAL_POST = 'PARTIALPOST'
export const SHIPMENT_STATUS_SENT_TO_STAGING = 'SENTTOSTG'
export const SHIPMENT_STATUS_SENT_TO_GP = 'SENTTOGP'
export const SHIPMENT_STATUS_INVOICED = 'INV'
export const SHIPMENT_STATUS_PROCESSING = 'PROCESSING'
export const SHIPMENT_STATUS_SALES_ORDER_APPROVED = 'SALESORDERAPPROVED'
export const SHIPMENT_STATUS_SALES_ORDER_PENDING = 'SALESORDERPENDING'

export const COMMON_STATUS_ALIAS_BILLED = 'Billed'

export const STATUSES_HUMAN_READABLE_NAMES_MAP = {
  // ALL isn't actually one of the statuses you can include in the API URL query
  // parameter. We put it here because our history forms will use this
  // object to populate the user's choices. If the user chooses all orders, the
  // api url should not include a "status" parameter

  [COMMON_STATUS_ALL]: 'All', // CODE_COMMENTS_61

  [KEG_ORDER_STATUS_PENDING]: 'Pending',
  [KEG_ORDER_STATUS_ACCEPTED]: 'Accepted',
  [KEG_ORDER_STATUS_COMPLETED]: 'Completed',
  [KEG_ORDER_STATUS_CANCELED]: 'Canceled',
  [KEG_ORDER_STATUS_ON_HOLD]: 'On Hold',

  [COLLAR_ORDER_STATUS_NEW_ORDER]: 'New Order',
  [COLLAR_ORDER_STATUS_ACCEPTED]: 'Accepted',
  [COLLAR_ORDER_STATUS_PRINTING]: 'Printing',
  [COLLAR_ORDER_STATUS_SHIPPING]: 'Shipping',
  [COLLAR_ORDER_STATUS_COMPLETED]: 'Completed',
  [COLLAR_ORDER_STATUS_CANCELED]: 'Canceled',
  [COLLAR_ORDER_STATUS_IN_REVIEW]: 'In Review',
  [COLLAR_ORDER_STATUS_READY_TO_POST]: 'Ready to Post',
  [COLLAR_ORDER_STATUS_PRE_POST]: 'Pre-Post',
  [COLLAR_ORDER_STATUS_POSTED]: 'Posted',
  [COLLAR_ORDER_STATUS_INVOICED]: 'Invoiced',
  [COLLAR_ORDER_STATUS_SENT_TO_GP]: 'Sent to GP',

  [SHIPMENT_STATUS_PENDING]: 'Pending',
  [SHIPMENT_STATUS_ALLOCATED]: 'Allocated',
  [SHIPMENT_STATUS_BOOKED]: 'Booked',
  [SHIPMENT_STATUS_IN_TRANSIT]: 'In Transit',
  [SHIPMENT_STATUS_DELIVERED]: 'Delivered',
  [SHIPMENT_STATUS_ACKNOWLEDGED]: 'Acknowledged',
  [SHIPMENT_STATUS_COMPLETED]: 'Completed',
  [SHIPMENT_STATUS_CANCELED]: 'Canceled',
  [SHIPMENT_STATUS_ON_HOLD]: 'On Hold',
  [SHIPMENT_STATUS_UNDER_REVIEW]: 'Under Review',
  [SHIPMENT_STATUS_PRE_POST]: 'Pre-Post',
  [SHIPMENT_STATUS_READY_TO_POST]: 'Ready to Post',
  [SHIPMENT_STATUS_POSTED]: 'Posted',
  [SHIPMENT_STATUS_PARTIAL_POST]: 'Partially Posted',
  [SHIPMENT_STATUS_INVOICED]: 'Invoiced',
  [SHIPMENT_STATUS_SENT_TO_STAGING]: 'Sent to Staging',
  [SHIPMENT_STATUS_SENT_TO_GP]: 'Sent to GP',
  [SHIPMENT_STATUS_PROCESSING]: 'Processing',
}


/*
 * *****************************************************************************
 * _contractMetadataObject (CODE_COMMENTS_133)
 * *****************************************************************************
 */


export const CONTRACT_METADATA_OBJECT_PROP_NAME = '_contractMetadataObject'
export const DEFAULT_OR_ORIGIN_CONTRACT_METADATA_OBJECT_PROP_NAME = 'defaultOrOriginContractMetaDataObject'
export const DEST_CONTRACT_METADATA_BRW_2_BRW_ONLY_PROP_NAME = 'destContractMetaDataBrw2BrwOnly'

export const CONTRACT_METADATA_PROP_NAME_PPF_CONTRACT_TYPE = 'ppfContractType'
export const CONTRACT_METADATA_PROP_NAME_CONTRACT_BREWER_CID = 'contractBrewerCid'
export const CONTRACT_METADATA_PROP_NAME_CONTRACT_TYPE = 'contractType'


/*
 * *****************************************************************************
 * Misc.
 * *****************************************************************************
 */

export const FORM_FIELD_PO_NUMBER_EXPLANATION = 'A customer order name or internal purchase order number that will be included for reference on your invoices'

/* eslint-disable react/no-multi-comp */

import React, { Component } from 'react'
import { Button, Modal, Grid } from 'semantic-ui-react'
import { Trans, withTranslation } from 'react-i18next'

import ModalWithCatastrophicFailureErrorBoundary from '../../semanticUiCustomComponents/componentsWithCatastrophicFailureErrorBoundary/ModalWithCatastrophicFailureErrorBoundary'

// Semantic UI React's <Modal> component is kind of a crappy design because its
// 'trigger' prop must be a component that does not get re-created on each
// re-render. What this means practically is that the type of 'trigger'
// component that the SubmitButtonWithConfirmFormInput component renders
// (<Button>, <Image>, etc) cannot be decided by the caller of the
// SubmitButtonWithConfirmFormInput component; it has to be defined just once
// and has to be the same for every component that uses
// SubmitButtonWithConfirmFormInput. Fortunately, in this app, all our submit
// buttons that use a Confirm Data modal can just be <Button> components: we're
// not getting creative with images as submit buttons or text as submit buttons.
// For this app, it's not a problem to hard-code a <Button> into this function.
//
// *Footnote: Technically you can use a component that gets re-created on each
// re-render as the 'trigger' prop of a <Modal> (for example, you can pass in a
// stateless functional component definition to use as the 'trigger' prop and it
// will work), but if you do, strange, undefined behavior ensues. Avoid it.
const SubmitButton = ({ buttonContent, ...rest }) => (
  <Button
    type="submit"
    {...rest} // github.com/Semantic-Org/Semantic-UI-React/issues/1187
  >
    {buttonContent}
  </Button>
)


class SubmitButtonWithConfirmFormInput extends Component {
  state = { modalOpen: false }

  handleOpen = () => {
    this.setState({ modalOpen: true },
      // sets the focus on the submit button. See CODE_COMMENTS_102
      () => {
        const $button = document.querySelector('#form-modal-confirm-info-submit-button')
        if ($button) { $button.focus() }
      },
    )
  }

  handleClose = () => this.setState({ modalOpen: false })

  handleSubmit = () => {
    this.setState({ modalOpen: false })
    this.props.handleSubmit()
  }

  render() {
    const {
      dataToConfirm,
      buttonContent='Submit',
      // We define this here so that it doesn't get included in ...rest, because
      // if it stays in rest, React will throw a warning in the console: "React
      // does not recognize the `handleSubmit` prop on a DOM element".
      handleSubmit,
      t: translate,
      ...rest // any other props passed in for the <Button> component
    } = this.props
    // eslint-disable-next-line react-hooks/rules-of-hooks

    return (
      <ModalWithCatastrophicFailureErrorBoundary // CODE_COMMENTS_131
        trigger={
          <SubmitButton
            buttonContent={translate(buttonContent)}
            {...rest}
            onClick={this.handleOpen}
          />}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size="tiny"
      >
        <Modal.Header>
          <Trans ns='common' i18nKey='confirmInfo'>Confirm Info</Trans>
        </Modal.Header>
        <Modal.Content>
          {/* For alignment purposes, all displayed content within this grid */}
          {/* needs to be contained within a <Grid.Column> component; see */}
          {/* CODE_COMMENTS_97 for why. */}
          <Grid columns={2}>
            {dataToConfirm.map(field => (
              <Grid.Row key={field.label}>
                <Grid.Column width={6} style={{ fontWeight: 'bold' }}>{`${field.label}:`}</Grid.Column>
                <Grid.Column width={10} style={{ overflowWrap: 'break-word' }}>{field.value}</Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            // This specifically needs to be type="button"; the button with
            // type="submit" is elsewhere
            type="button" // CODE_COMMENTS_260
            color="grey"
            size="small"
            onClick={this.handleClose}
          >
            <Trans ns='common' i18nKey='edit'>Edit</Trans>
          </Button>
          <Button
            // This specifically needs to be type="button"; the button with
            // type="submit" is elsewhere
            type="button" // CODE_COMMENTS_260
            color="green"
            onClick={this.handleSubmit}
            // github.com/Semantic-Org/Semantic-UI-React/issues/1740#issuecomment-307451467
            id="form-modal-confirm-info-submit-button"
          >
            <Trans ns='common' i18nKey='submit'>Submit</Trans>
          </Button>
        </Modal.Actions>
      </ModalWithCatastrophicFailureErrorBoundary>
    )
  }
}

export default withTranslation()(SubmitButtonWithConfirmFormInput)

/**
 * CODE_COMMENTS_219
 */

/* eslint-disable max-len */

import React from 'react'

import InformationalPopup from '../../../common-components/InformationalPopup'

import {
  getItemSkuIdQtyFromLineItem, mapItemSkuIdsForQualityLevel,
} from '../../../redux/selectors/rewrite/itemSkus'
import {
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../../redux/selectors/rewrite/subsidiaries'

import {
  INVENTORY_REPORT_OBJS,
  INVENTORY_REPORT_OBJS_PUBS,
  INVENTORY_REPORT_OBJS_CONTRACT_BREWERS,
  INVENTORY_REPORT_OBJS_CONTRACTEES,

  INFO_JUST_FOR_THE_WEB_APP,
  INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME,
  INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID,
  INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID,
  INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,
  INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL,
  CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS,
} from './constants'

import {
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_EMPTY,
  API_INVENTORY_TYPE_EMPTY,
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_FULL,
  API_INVENTORY_TYPE_FULL,
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_OFFSITE_WAREHOUSE,
  API_INVENTORY_TYPE_OFFSITE_WAREHOUSE,
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_DEFECTIVE,
  API_INVENTORY_TYPE_DEFECTIVE,
  API_INVENTORY_TYPE_TOTAL_SHIPPED,
  API_INVENTORY_TYPE_TOTAL_RECEIVED,
  API_INVENTORY_TYPE_TOTAL_FILLED,
  API_INVENTORY_TYPE_TOTAL,
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_SORTED,
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_UNSORTED,
  FIELD_LABEL_TOTAL_KEGS_NO_PUBS_CLEAN,
  API_INVENTORY_TYPE_PALLET_UNDER_PRODUCT,
  API_INVENTORY_TYPE_PALLET_IN_USE,
} from '../../../constants/formAndApiUrlConfig/reportInventory'

import {
  createDynamicFormFieldName,
  getMostRepresentativeInventoryReportObj,
} from './'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
} from '../../../utils'
import {
  ITEM_SKUS_SKU_CLEAN_QUALITY_LEVEL,
  ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL,
  ITEM_SKUS_SKU_SORTED_QUALITY_LEVEL,
  ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
} from '../../../constants'

export function createLabelToApiInventoryTypeMapBrwEmptysFullsEtcNoPubs({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  entireSubsidiariesSlice,
  subsidiaryId,
}) {
  const o = getMostRepresentativeInventoryReportObj({ [INVENTORY_REPORT_OBJS]: inventoryReportObjs })
  return [
    {
      label: getIsSubsidiaryATypeThatShouldTrackForeignKegs({
        entireSubsidiariesSlice,
        subsidiaryId,
      })
        ? `${FIELD_LABEL_TOTAL_KEGS_NO_PUBS_EMPTY} (no foreign)`
        : FIELD_LABEL_TOTAL_KEGS_NO_PUBS_EMPTY,
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_EMPTY,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_EMPTY,
        itemSkuId,
      ),
    },
    {
      label: getIsSubsidiaryATypeThatShouldTrackForeignKegs({
        entireSubsidiariesSlice,
        subsidiaryId,
      })
        ? `${FIELD_LABEL_TOTAL_KEGS_NO_PUBS_FULL} (no foreign)`
        : FIELD_LABEL_TOTAL_KEGS_NO_PUBS_FULL,
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_FULL,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_FULL,
        itemSkuId,
      ),
    },
    {
      label: getIsSubsidiaryATypeThatShouldTrackForeignKegs({
        entireSubsidiariesSlice,
        subsidiaryId,
      })
        ? `${FIELD_LABEL_TOTAL_KEGS_NO_PUBS_OFFSITE_WAREHOUSE} (no foreign)`
        : FIELD_LABEL_TOTAL_KEGS_NO_PUBS_OFFSITE_WAREHOUSE,
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_OFFSITE_WAREHOUSE,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_OFFSITE_WAREHOUSE,
        itemSkuId,
      ),
    },
    {
      label: getIsSubsidiaryATypeThatShouldTrackForeignKegs({
        entireSubsidiariesSlice,
        subsidiaryId,
      })
        ? `${FIELD_LABEL_TOTAL_KEGS_NO_PUBS_DEFECTIVE} (no foreign)`
        : FIELD_LABEL_TOTAL_KEGS_NO_PUBS_DEFECTIVE,
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_DEFECTIVE,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_DEFECTIVE,
        itemSkuId,
      ),
    },
  ]
}


export function createLabelToApiInventoryTypeMapPubs({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  const objs = inventoryReportObjs[INVENTORY_REPORT_OBJS_PUBS]
  if (!objs) { return [] }
  return objs.map(o => ({
    label: o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME],
    initialValueFunc: itemSkuId => (
      getItemSkuIdQtyFromLineItem({
        lineItems: o.inventoryLineItemObjects,
        itemSkuId,
        type: API_INVENTORY_TYPE_TOTAL,
      }) || 0
    ),
    fieldNameFunc: itemSkuId => createDynamicFormFieldName(
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
      API_INVENTORY_TYPE_TOTAL,
      itemSkuId,
    ),
  }))
}


export function createLabelToApiInventoryTypeMapContractBrewersOfBrw({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  const objs = inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACT_BREWERS]
  if (!objs) { return [] }
  return objs.map(o => ({
    label: `Filled by ${o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME]}`,
    initialValueFunc: itemSkuId => (
      getItemSkuIdQtyFromLineItem({
        lineItems: o.inventoryLineItemObjects,
        itemSkuId,
        type: API_INVENTORY_TYPE_FULL,
      }) || 0
    ),
    fieldNameFunc: itemSkuId => createDynamicFormFieldName(
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID],
        o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID],
      ),
      API_INVENTORY_TYPE_FULL,
      itemSkuId,
    ),
  }))
}


export function createLabelToApiInventoryMapConbrwEmptysDefectivesEtc(props) {
  return createLabelToApiInventoryTypeMapBrwEmptysFullsEtcNoPubs(props).filter(
    o => !o.label.includes(FIELD_LABEL_TOTAL_KEGS_NO_PUBS_FULL),
  )
}


export function createLabelToApiInventoryMapContracteesOfConbrw({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  const objs = inventoryReportObjs[INVENTORY_REPORT_OBJS_CONTRACTEES]
  if (!objs) { return [] }
  return objs.map(o => ({
    label: o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME],
    initialValueFunc: itemSkuId => (
      getItemSkuIdQtyFromLineItem({
        lineItems: o.inventoryLineItemObjects,
        itemSkuId,
        type: API_INVENTORY_TYPE_FULL,
      }) || 0
    ),
    fieldNameFunc: itemSkuId => createDynamicFormFieldName(
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(
        o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID],
        o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID],
      ),
      API_INVENTORY_TYPE_FULL,
      itemSkuId,
    ),
  }))
}


export function createLabelToApiInventoryTypeMapBrwTotalsShippedAndReceived({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  const o = getMostRepresentativeInventoryReportObj({ [INVENTORY_REPORT_OBJS]: inventoryReportObjs })
  return [
    {
      label: 'Total Kegs Shipped Out',
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_TOTAL_SHIPPED,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_TOTAL_SHIPPED,
        itemSkuId,
      ),
      negativeValuesAllowed: true,
    },
    {
      label: 'Total Kegs Received In',
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_TOTAL_RECEIVED,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_TOTAL_RECEIVED,
        itemSkuId,
      ),
      negativeValuesAllowed: true,
    },
  ]
}


export function createLabelToApiInventoryMapConbrwTotalsFilledAndReceived({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
}) {
  const o = getMostRepresentativeInventoryReportObj({ [INVENTORY_REPORT_OBJS]: inventoryReportObjs })
  return [
    {
      label: 'Total Non-Foreign Kegs Received In',
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_TOTAL_RECEIVED,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_TOTAL_RECEIVED,
        itemSkuId,
      ),
      arbitraryContentAfterLabel: () => (
        <InformationalPopup
          iconName="info"
          content="This does NOT include foreign kegs"
        />
      ),
      negativeValuesAllowed: true,
    },
    {
      label: 'Total Kegs Filled For All Brewers',
      initialValueFunc: itemSkuId => (
        getItemSkuIdQtyFromLineItem({
          lineItems: o.inventoryLineItemObjects,
          itemSkuId,
          type: API_INVENTORY_TYPE_TOTAL_FILLED,
        }) || 0
      ),
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_TOTAL_FILLED,
        itemSkuId,
      ),
      arbitraryContentAfterLabel: () => (
        <InformationalPopup
          iconName="info"
          content="These are keg fills, not physical shipments of the kegs. Whether the keg has been physically shipped or not is irrelevant for this field."
        />
      ),
    },
  ]
}


export function createLabelToApiInventoryMapDistEmptys({
  [INVENTORY_REPORT_OBJS]: inventoryReportObjs,
  entireItemSkusSlice,
  isThisForDisplayingAnAlreadyCreatedInventoryReport,
  isEditForm,
  subsidiaryId,
}) {
  const o = getMostRepresentativeInventoryReportObj({ [INVENTORY_REPORT_OBJS]: inventoryReportObjs })
  if (!isThisForDisplayingAnAlreadyCreatedInventoryReport && !isEditForm && subsidiaryId === 24) {
    return [
      {
        label: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_DEFECTIVE,
        initialValueFunc: itemSkuId => (
          getItemSkuIdQtyFromLineItem({
            lineItems: o.inventoryLineItemObjects,
            itemSkuId: mapItemSkuIdsForQualityLevel({
              filterQualityLevel: ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL,
              entireItemSkusSlice,
              itemSkuIds: [itemSkuId],
            })?.[0],
            type: API_INVENTORY_TYPE_EMPTY,
          }) || 0
        ),
        fieldNameFunc: itemSkuId => createDynamicFormFieldName(
          createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
          API_INVENTORY_TYPE_EMPTY,
          mapItemSkuIdsForQualityLevel({
            filterQualityLevel: ITEM_SKUS_SKU_DEFECTIVE_QUALITY_LEVEL,
            entireItemSkusSlice,
            itemSkuIds: [itemSkuId],
          })?.[0],
        ),
      },
      {
        label: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_SORTED,
        initialValueFunc: itemSkuId => (
          getItemSkuIdQtyFromLineItem({
            lineItems: o.inventoryLineItemObjects,
            itemSkuId: mapItemSkuIdsForQualityLevel({
              filterQualityLevel: ITEM_SKUS_SKU_SORTED_QUALITY_LEVEL,
              entireItemSkusSlice,
              itemSkuIds: [itemSkuId],
            })?.[0],
            type: API_INVENTORY_TYPE_EMPTY,
          }) || 0
        ),
        fieldNameFunc: itemSkuId => createDynamicFormFieldName(
          createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
          API_INVENTORY_TYPE_EMPTY,
          mapItemSkuIdsForQualityLevel({
            filterQualityLevel: ITEM_SKUS_SKU_SORTED_QUALITY_LEVEL,
            entireItemSkusSlice,
            itemSkuIds: [itemSkuId],
          })?.[0],
        ),
      },
      {
        label: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_UNSORTED,
        initialValueFunc: itemSkuId => (
          getItemSkuIdQtyFromLineItem({
            lineItems: o.inventoryLineItemObjects,
            itemSkuId: mapItemSkuIdsForQualityLevel({
              filterQualityLevel: ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
              entireItemSkusSlice,
              itemSkuIds: [itemSkuId],
            })?.[0],
            type: API_INVENTORY_TYPE_EMPTY,
          }) || 0
        ),
        fieldNameFunc: itemSkuId => createDynamicFormFieldName(
          createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
          API_INVENTORY_TYPE_EMPTY,
          mapItemSkuIdsForQualityLevel({
            filterQualityLevel: ITEM_SKUS_SKU_UNSORTED_QUALITY_LEVEL,
            entireItemSkusSlice,
            itemSkuIds: [itemSkuId],
          })?.[0],
        ),
      },
      {
        label: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_CLEAN,
        initialValueFunc: itemSkuId => (
          getItemSkuIdQtyFromLineItem({
            lineItems: o.inventoryLineItemObjects,
            itemSkuId: mapItemSkuIdsForQualityLevel({
              filterQualityLevel: ITEM_SKUS_SKU_CLEAN_QUALITY_LEVEL,
              entireItemSkusSlice,
              itemSkuIds: [itemSkuId],
            })?.[0],
            type: API_INVENTORY_TYPE_EMPTY,
          }) || 0
        ),
        fieldNameFunc: itemSkuId => createDynamicFormFieldName(
          createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
          API_INVENTORY_TYPE_EMPTY,
          mapItemSkuIdsForQualityLevel({
            filterQualityLevel: ITEM_SKUS_SKU_CLEAN_QUALITY_LEVEL,
            entireItemSkusSlice,
            itemSkuIds: [itemSkuId],
          })?.[0],
        ),
      },
    ]
  } else {
    return [
      {
        label: FIELD_LABEL_TOTAL_KEGS_NO_PUBS_EMPTY,
        initialValueFunc: itemSkuId => (
          getItemSkuIdQtyFromLineItem({
            lineItems: o.inventoryLineItemObjects,
            itemSkuId,
            type: API_INVENTORY_TYPE_EMPTY,
            }) || 0
        ),
        fieldNameFunc: itemSkuId => createDynamicFormFieldName(
          createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
          API_INVENTORY_TYPE_EMPTY,
          itemSkuId,
        ),
      },
    ]
  }
}

// CODE_COMMENTS_267
export function creatorOfCreateLabelToApiInventoryMapDistNoLoginRequired({
  [INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: objs,
}) {
  return () => (
    objs.map(o => ({
      label: o[INFO_JUST_FOR_THE_WEB_APP][INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL],
      initialValueFunc: () => 0,
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(o.customerId),
        API_INVENTORY_TYPE_EMPTY,
        itemSkuId,
      ),
    }))
  )
}

export function creatorOfCreateLabelToApiConstellationMapDistNoLoginRequired({
  [CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS]: objs,
}) {
  return () => {
    const emptyPallets = {
      label: 'Loose or Ready to ship CBI Case Plastic Pallets',
      initialValueFunc: () => 0,
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(objs.customerId),
        API_INVENTORY_TYPE_EMPTY,
        itemSkuId,
      ),
    }
    const fullPallets = {
      label: 'CBI Case Plastic Pallets Under Product',
      initialValueFunc: () => 0,
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(objs.customerId),
        API_INVENTORY_TYPE_PALLET_UNDER_PRODUCT,
        itemSkuId,
      ),
    }
    const shippedPallets = {
      label: 'CBI Case Plastic Pallets Out for Product Delivery (On Trucks)',
      initialValueFunc: () => 0,
      fieldNameFunc: itemSkuId => createDynamicFormFieldName(
        createCustIdAndOptionalConbrwCustIdReduxIdentifier(objs.customerId),
        API_INVENTORY_TYPE_PALLET_IN_USE,
        itemSkuId,
      ),
    }
    const toReturn = [emptyPallets, fullPallets, shippedPallets]
    return toReturn
  }
}
